import { __assign } from 'tslib';
import React, { forwardRef } from 'react';

function withForwardedRef(Comp) {
    var WrappedComp = function (props, ref) { return (React.createElement(Comp, __assign({}, props, { forwardedRef: ref }))); };
    var name = Comp.displayName || Comp.name;
    WrappedComp.displayName = "withForwardedRef(" + name + ")";
    return forwardRef(WrappedComp);
}

export { withForwardedRef as default };
